import React, { useEffect, useState } from 'react';
import { Button, Pagination, SpaceBetween, Spinner, Table, TableProps, TextFilter } from '@amzn/awsui-components-react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import Header from '@amzn/awsui-components-react/polaris/header';
import { ColumnDefinitions, PaginationLabels, TableEmptyState, TableNoMatchState } from './details-table-config';
import * as APIt from 'src/API';
import { useBundle } from '@amzn/react-arb-tools';
import { VisitorTypes } from 'src/constants/Constants';
import { debug } from 'src/utils/commonUtils';

export interface IManageVisitorRequestDetailsTablePanelProps {
  isLoading: boolean;
  refreshCallback: Function;
  requestEscorts: APIt.RequestEscort[];
  visitorType: VisitorTypes;
}

export default function ManageVisitorRequestDetailsTablePanel(props: IManageVisitorRequestDetailsTablePanelProps) {
  debug(`ManageVisitorRequestDetailsTablePanel() props is ${JSON.stringify(props)}`);

  const [allItems, setAllItems] = useState<APIt.RequestEscort[]>(props.requestEscorts);
  const [hideTable, setHideTable] = useState<boolean>(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);

  const [bundle, isBundleLoading] = useBundle('components.Management.ManageVisitors.DetailsTablePanel');

  const pageSize = 5;

  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps } = useCollection(
    allItems,
    {
      filtering: {
        empty:
          <TableEmptyState
            title={
              props.visitorType == VisitorTypes.Visitor
                ? !isBundleLoading && !props.isLoading
                  ? bundle.getMessage('no-escorts-found')
                  : 'No Escorts Found'
                : props.visitorType == VisitorTypes.UnescortedVendor
                  ? !isBundleLoading && !props.isLoading
                    ? bundle.getMessage('no-amazon-points-of-contact-found')
                    : 'No Amazon Points of Contact Found'
                  : ''
            }
          />,
        noMatch: <TableNoMatchState onClearFilter={() => actions.setFiltering('')} />
      },
      pagination: { pageSize: pageSize },
      sorting: {},
      selection: { trackBy: 'id' }
    }
  );

  const refreshBtnClickHandler = async () => {
    setRefreshing(true);
    await props.refreshCallback();
    setRefreshing(false);
  };

  const itemsCount = (): number => {
    if (allItems) return allItems.length;
    return 0;
  };

  const getFilterCounterText = (count: number) => `${count} ${count === 1 ? 'match' : 'matches'}`;

  useEffect(() => {
    setAllItems(props.requestEscorts);
  }, [props.requestEscorts])

  if (isBundleLoading) return <Spinner/>;

  return(
    <>
    <div id='tableDiv' hidden={hideTable}>
      <Table
        {...collectionProps}
        columnDefinitions={ColumnDefinitions.filter(cd => { 
          if (props.visitorType == VisitorTypes.Visitor && cd.id == 'escortId') return cd;
          if (props.visitorType == VisitorTypes.UnescortedVendor && cd.id == 'pointOfContactEscortId') return cd;
        })}
        filter={
          <TextFilter
            {...filterProps}
            filteringAriaLabel={
              props.visitorType == VisitorTypes.Visitor
                ? bundle.getMessage('filter-escorts')
                : bundle.getMessage('filter-amazon-points-of-contact')
            }
            filteringPlaceholder={
              props.visitorType == VisitorTypes.Visitor
                ? bundle.getMessage('find-escorts')
                : bundle.getMessage('find-amazon-points-of-contact')
            }
            countText={getFilterCounterText(filteredItemsCount === undefined ? 0: filteredItemsCount)}
          />
        }
        header={
          <Header
            counter={`(${itemsCount().toString()})`}
            actions={
              <>
              <SpaceBetween size='xs' direction='horizontal'>
                <Button loading={refreshing} key="refreshBtn" onClick={refreshBtnClickHandler} iconName='refresh'>{bundle.getMessage('refresh')}</Button>
              </SpaceBetween>
              </>
            }
          >
            {
              props.visitorType == VisitorTypes.Visitor
                ? bundle.getMessage('escorts')
                : bundle.getMessage('amazon-points-of-contact')}
          </Header>
        }
        items={items}
        loading={props.isLoading}
        loadingText={
          props.visitorType == VisitorTypes.Visitor
            ? bundle.getMessage('loading-escorts')
            : bundle.getMessage('loading-amazon-points-of-contact')
        }
        pagination={
          allItems.length > pageSize
          &&
          <Pagination
            {...paginationProps}
            ariaLabels={PaginationLabels}
          />
        }
        resizableColumns={true}
        stickyHeader={true}
        trackBy='id'
      />
    </div>
    </>
  );
}
