import React, { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query'
import { Cards, Container, FormField, Grid, Spinner } from '@amzn/awsui-components-react';
import { useBundle } from '@amzn/react-arb-tools';
import { AmazonOrangeColor, ApprovalStatus } from 'src/constants/Constants';
import * as APIt from 'src/API';
import { DateFunctions } from 'src/components/utils';
import { Link } from 'react-router-dom';
import { debug } from 'src/utils/commonUtils';

interface ISelfServiceHomeProps {
  setPageCallback: Function;
  username: string;
}

export default function SelfServiceHome(props: ISelfServiceHomeProps) {
  debug(`SelfServiceHome() props is ${JSON.stringify(props)}`);

  const queryClient = useQueryClient();

  const [visitorAssets, setVisitorAssets] = useState<APIt.VisitorAsset[]>(queryClient.getQueryData(['visitorAssets']) || []);
  const [pendingVisitorAccessLevels, setPendingVisitorAccessLevels] = useState<APIt.VisitorAccessLevel[]>(queryClient.getQueryData(['pendingVisitorAccessLevels']) || []);
  const [pendingVisitorRequests, setPendingVisitorRequests] = useState<APIt.VisitorRequest[]>(queryClient.getQueryData(['pendingVisitorRequests']) || []);

  const [bundle, isBundleLoading] = useBundle('components.SelfService.Home.index');

  const total = <T extends {updated: string, approval_status?: string | null, status?: string | null}>(
    values: T[],
    approvalStatus: ApprovalStatus,
    date: Date): number =>
  {
    let total = 0;
    total = values.reduce((total, value) => {
      const yearMonth = `${date.getFullYear()}-${date.getMonth()+1}`;
      const updatedDate = new Date(value.updated);
      const updatedYearMonth = `${updatedDate.getFullYear()}-${updatedDate.getMonth()}`;
      let status = value.status || value.approval_status;
      status == approvalStatus && updatedYearMonth == yearMonth ? total++ : total; return total
      }, 0);
    return total;
  };

  useEffect(() => {
    setVisitorAssets(queryClient.getQueryData(['visitorAssets']) || []);
  }, [queryClient.getQueryData(['visitorAssets'])]);

  useEffect(() => {
    setPendingVisitorAccessLevels(queryClient.getQueryData(['pendingVisitorAccessLevels']) || []);
  }, [queryClient.getQueryData(['pendingVisitorAccessLevels'])]);

  useEffect(() => {
    setPendingVisitorRequests(queryClient.getQueryData(['pendingVisitorRequests']) || []);
  }, [queryClient.getQueryData(['pendingVisitorRequests'])]);

  if (isBundleLoading) return <Spinner/>;

  return(
    <Container>
      <Cards
        cardDefinition={{
          header: card =>
            <Link
              onClick={(e) => {
                props.setPageCallback(card.link, card.tab);
              } }
              style={{ color: AmazonOrangeColor }}
              to=''
            >
              {bundle.getMessage(card.code)}
            </Link>,
          sections: [
            {
              content: e => e.pending,
              header: bundle.getMessage('pending'),
              id: 'pending',
            },
            {
              content: e =>
                <Grid gridDefinition={[{colspan: 6},{colspan: 6}]}>
                  <FormField label={bundle.getMessage('approved')}>
                    {e.approvedCurrentMonth}
                  </FormField>
                  <FormField label={bundle.getMessage('denied')}>
                    {e.deniedCurrentMonth}
                  </FormField>
                </Grid>,
              header: bundle.getMessage('current-month'),
              id: 'currentMonth',
            },
            {
              content: e =>
                <Grid gridDefinition={[{colspan: 6},{colspan: 6}]}>
                  <FormField label={bundle.getMessage('approved')}>
                    {e.approvedPriorMonth}
                  </FormField>
                  <FormField label={bundle.getMessage('denied')}>
                    {e.deniedPriorMonth}
                  </FormField>
                </Grid>,
              header: bundle.getMessage('prior-month'),
              id: 'priorMonth',
            },
          ]
        }}
        cardsPerRow={[
          { cards: 4 },
          { minWidth: 500, cards: 4 }
        ]}
        items={[
          {
            alt: 'Pending Access Requests',
            approvedPriorMonth:
              <>
                {
                  queryClient.isFetching({ queryKey: ['pendingVisitorAccessLevels'] })
                    ? <Spinner/>
                    : total(pendingVisitorAccessLevels, ApprovalStatus.Approved, DateFunctions.getFirstDayOfPriorMonth())
                }
              </>,
            approvedCurrentMonth:
              <>
                {
                  queryClient.isFetching({ queryKey: ['pendingVisitorAccessLevels'] })
                    ? <Spinner/>
                    : total(pendingVisitorAccessLevels, ApprovalStatus.Approved, DateFunctions.getFirstDayOfCurrentMonth())
                }
              </>,
            code: 'pending-access-requests',
            deniedPriorMonth:
              <>
                {
                  queryClient.isFetching({ queryKey: ['pendingVisitorAccessLevels'] })
                    ? <Spinner/>
                    : total(pendingVisitorAccessLevels, ApprovalStatus.Denied, DateFunctions.getFirstDayOfPriorMonth())
                }
              </>,
            deniedCurrentMonth:
              <>
                {
                  queryClient.isFetching({ queryKey: ['pendingVisitorAccessLevels'] })
                    ? <Spinner/>
                    : total(pendingVisitorAccessLevels, ApprovalStatus.Denied, DateFunctions.getFirstDayOfCurrentMonth())
                }
              </>,
            link: '/selfservice/pendingaccessrequests',
            name: 'SelfServiceHomePendingAcessRequestsCard',
            pending: <>{queryClient.isFetching({ queryKey: ['pendingVisitorAccessLevels'] }) ? <Spinner/> : pendingVisitorAccessLevels.length}</>,
            tab: 'Pending Access Requests',
          },
          {
            alt: 'Pending Visitor Requests',
            approvedPriorMonth:
              <>
                {
                  queryClient.isFetching({ queryKey: ['pendingVisitorRequests'] })
                    ? <Spinner/>
                    : total(pendingVisitorRequests, ApprovalStatus.Approved, DateFunctions.getFirstDayOfPriorMonth())
                }
              </>,
            approvedCurrentMonth:
              <>
                {
                  queryClient.isFetching({ queryKey: ['pendingVisitorRequests'] })
                    ? <Spinner/>
                    : total(pendingVisitorRequests, ApprovalStatus.Approved, DateFunctions.getFirstDayOfCurrentMonth())
                }
              </>,
            code: 'pending-visitor-requests',
            deniedPriorMonth:
              <>
                {
                  queryClient.isFetching({ queryKey: ['pendingVisitorRequests'] })
                    ? <Spinner/>
                    : total(pendingVisitorRequests, ApprovalStatus.Denied, DateFunctions.getFirstDayOfPriorMonth())
                }
              </>,
            deniedCurrentMonth:
              <>
                {
                  queryClient.isFetching({ queryKey: ['pendingVisitorRequests'] })
                    ? <Spinner/>
                    : total(pendingVisitorRequests, ApprovalStatus.Denied, DateFunctions.getFirstDayOfCurrentMonth())
                }
              </>,
            link: '/selfservice/pendingvisitorrequests',
            name: 'SelfServiceHomePendingVisitorRequestsCard',
            pending: <>{queryClient.isFetching({ queryKey: ['pendingVisitorRequests'] }) ? <Spinner/> : pendingVisitorRequests.length}</>,
            tab: 'Pending Visitor Requests',
          },
          {
            alt: 'My Assets',
            approvedPriorMonth: 
              <>
                {
                  queryClient.isFetching({ queryKey: ['visitorAssets'] })
                    ? <Spinner/>
                    : total(visitorAssets, ApprovalStatus.Approved, DateFunctions.getFirstDayOfPriorMonth())
                }
              </>,
            approvedCurrentMonth:
              <>
                {
                  queryClient.isFetching({ queryKey: ['visitorAssets'] })
                    ? <Spinner/>
                    : total(visitorAssets, ApprovalStatus.Approved, DateFunctions.getFirstDayOfCurrentMonth())
                }
              </>,
            code: 'my-assets',
            deniedPriorMonth:
              <>
                {
                  queryClient.isFetching({ queryKey: ['visitorAssets'] })
                    ? <Spinner/>
                    : total(visitorAssets, ApprovalStatus.Denied, DateFunctions.getFirstDayOfPriorMonth())
                }
              </>,
            deniedCurrentMonth:
              <>
                {
                  queryClient.isFetching({ queryKey: ['visitorAssets'] })
                    ? <Spinner/>
                    : total(visitorAssets, ApprovalStatus.Denied, DateFunctions.getFirstDayOfCurrentMonth())
                }
              </>,
            link: '/selfservice/viewmyassets',
            name: 'SelfServiceHomeMyAssetsCard',
            pending:
              <>
                {
                  queryClient.isFetching({ queryKey: ['visitorAssets'] })
                    ? <Spinner/>
                    : visitorAssets.reduce((total, va) => {
                        va.approval_status == ApprovalStatus.PendingApproval ? total++ : total;
                        return total;
                      }, 0) || 0
                }
              </>,
            tab: 'My Assets',
          },
        ]}
        loadingText='Loading resources'
        trackBy='name'
        visibleSections={
          [
            'pending',
            'currentMonth',
            'priorMonth',
          ]}
      />
    </Container>
  );
}